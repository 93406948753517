import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-5 bg-indigo-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quote = _resolveComponent("quote")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_quote, null, {
      quote: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.body), 1)
      ]),
      cite: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.author), 1)
      ]),
      _: 1
    })
  ]))
}