
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Box',
    props: {
        cls: {
            type: String,
            default: ''
        }
    }
});
