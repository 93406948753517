
import { defineComponent } from 'vue';
import Box from './Box.vue';

export default defineComponent({
    name: 'Quote',
    components: {
        Box
    }
});
