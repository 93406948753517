
import { defineComponent } from 'vue';
import Landing from '@/components/Landing.vue';

export default defineComponent({
    name: 'Home',
    components: {
        Landing
    }
});
