
import { defineComponent } from 'vue';
import LandingGoodGirlKarma from './LandingGoodGirlKarma.vue';
import LandingTreasuresGift from './LandingTreasuresGift.vue';
import LandingBody from './LandingBody.vue';

export default defineComponent({
    name: 'Landing',
    components: {
        LandingGoodGirlKarma,
        LandingTreasuresGift,
        LandingBody
    },
    props: {}
});
