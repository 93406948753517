
import { defineComponent } from 'vue';
import QuoteBox from './QuoteBox.vue';
import BuyButton from './BuyButton.vue';

export default defineComponent({
    name: 'LandingBody',
    components: {
        QuoteBox,
        BuyButton
    }
});
