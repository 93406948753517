
import { defineComponent } from 'vue';
import Quote from './Quote.vue';

export default defineComponent({
    name: 'QuoteBox',
    components: {
        Quote
    },
    props: {
        body: { required: true, type: String },
        author: { required: true, type: String }
    }
});
