import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto text-2xl border-2 border-black mb-4" }
const _hoisted_2 = {
  class: "",
  "w-full": ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navigation = _resolveComponent("navigation")
  const _component_router_view = _resolveComponent("router-view")
  const _component_spinner = _resolveComponent("spinner")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_navigation),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_spinner)
  ]))
}