
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Navigation',
    data() {
        return {
            routes: [
                { to: '/', label: 'Home' },
                { to: '/GoodGirlKarma', label: 'Good Girl, Karma' },
                { to: '/TreasuresGift', label: "Treasure's Gift" },
                { to: '/Purchase', label: 'Purchase' }
            ]
        };
    }
});
