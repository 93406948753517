
import { defineComponent } from 'vue';
import Navigation from '@/components/Navigation.vue';
import Spinner from '@/components/Spinner.vue';

export default defineComponent({
    name: 'App',
    components: {
        Navigation,
        Spinner
    }
});
